import { graphql, useStaticQuery } from "gatsby";

import { DetailListTranslationQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const detailListTranslationQuery =
  (): DetailListTranslationQuery["allLocalizableJson"]["edges"][number]["node"]["detail"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } =
      useStaticQuery<DetailListTranslationQuery>(graphql`
        query DetailListTranslation {
          allLocalizableJson {
            edges {
              node {
                detail {
                  busStop
                  enlarge
                  hospitalMap
                  metroStop
                  parkingNearby
                  publicTransportNearby
                  tramStop
                }
                language
              }
            }
          }
        }
      `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.detail)[0];
  };

export default detailListTranslationQuery;
