import DirectionsIcon from "@mui/icons-material/Directions";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { IconButton } from "gatsby-theme-material-ui";
import * as React from "react";

import DistanceToTransport from "./DistanceToTransport";
import ParkingIcon from "./icons/ParkingIcon";

interface Props {
  divider?: boolean;
  distance: string;
  label: string;
  latLng: { lat: number; lng: number };
}

function ParkingDetailListItem({
  divider,
  label,
  distance,
  latLng,
}: Props): JSX.Element {
  return (
    <ListItem
      divider={divider}
      secondaryAction={
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          to={`https://www.google.com/maps/dir/?api=1&destination=${latLng.lat},${latLng.lng}`}
          edge="end"
          aria-label="navigate"
        >
          <DirectionsIcon color="primary" />
        </IconButton>
      }
    >
      <ListItemIcon>
        <ParkingIcon />
      </ListItemIcon>
      <ListItemText>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography fontSize="12px">{label}</Typography>
          <DistanceToTransport distance={distance} />
        </Stack>
      </ListItemText>
    </ListItem>
  );
}

export default ParkingDetailListItem;
