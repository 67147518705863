import { Paper, Stack, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { graphql, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useContext } from "react";

import Controls from "../../components/Controls";
import Detail from "../../components/Detail";
import GoBackButton from "../../components/GoBackButton";
import StoreContext from "../../context/StoreContext";
import { DetailPageQuery } from "../../graphqlTypes";

const imagesByCSVID = {
  1: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/Budova - dron.jpg"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/detska-externi-1.jpg"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/detska-externi-2.jpg"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/detska-interni-1.JPG"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/detska-interni-2.JPG"
        alt="hospital image"
      />
    </>
  ),
  2: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/Budova - dron.jpg"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/dospela-externi-1.JPG"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/dospela-externi-2.JPG"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/dospela-externi-3.JPG"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/dospela-externi-4.JPG"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Motol/Foto pro aplikaci LSP/dospela-interni.JPG"
        alt="hospital image"
      />
    </>
  ),
  3: (
    <StaticImage
      style={{ minWidth: "90%" }}
      src="../../images/Synetech/Fakultni nemocnice Kralovske Vinohrady/Vstup.jpg"
      alt="hospital image"
    />
  ),
  4: (
    <StaticImage
      style={{ minWidth: "90%" }}
      src="../../images/Synetech/Fakultni nemocnice Kralovske Vinohrady/Vstup.jpg"
      alt="hospital image"
    />
  ),
  5: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Vseobecna fakultni nemocnice/DSC03855.JPG"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Vseobecna fakultni nemocnice/detska-1.jpg"
        alt="detska"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Vseobecna fakultni nemocnice/detska-2.jpg"
        alt="detska"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Vseobecna fakultni nemocnice/detska-3.jpg"
        alt="detska"
      />
    </>
  ),
  6: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/Hlavni-1.jpeg"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/hlavni_2.jpeg"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/Hlavni_3.jpeg"
        alt="hospital image"
      />
    </>
  ),
  7: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/Hlavni-1.jpeg"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/hlavni_2.jpeg"
        alt="hospital image"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Bulovce/Hlavni_3.jpeg"
        alt="hospital image"
      />
    </>
  ),
  8: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstup na LPS.png"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstupni.jpg"
        alt="vstup"
      />
    </>
  ),
  9: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstup na LPS.png"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstupni.jpg"
        alt="vstup"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vseobecna.png"
        alt="vseobecna"
      />
    </>
  ),
  10: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstup na LPS.png"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Fakultni Thomayerova nemocnice/Vstupni.jpg"
        alt="vstup"
      />
    </>
  ),
  11: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Ustredni vojenska nemocnice/uvn_koptera_2017.jpeg"
        alt="dron"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Ustredni vojenska nemocnice/DSC_2498.jpeg"
        alt="vstup"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Ustredni vojenska nemocnice/DSC_8988.jpeg"
        alt="uvnitr"
      />
    </>
  ),
  12: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Poliklinika Praha/Budova.jpg"
        alt="Foto budovy"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Poliklinika Praha/Vratnice.jpg"
        alt="Foto na vratnici"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Poliklinika Praha/Zubni.jpg"
        alt="Pohled na interier"
      />
    </>
  ),
  13: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/20191011_142838.jpg"
        alt="Foto budovy"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B115.jpg"
        alt="Foto na vratnici"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B61.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B62.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B63.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B64.jpg"
        alt="Pohled na interier"
      />
    </>
  ),
  14: (
    <>
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/20191011_142838.jpg"
        alt="Foto budovy"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B115.jpg"
        alt="Foto na vratnici"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B61.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B62.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B63.jpg"
        alt="Pohled na interier"
      />
      <StaticImage
        style={{ minWidth: "90%" }}
        src="../../images/Synetech/Na Frantisku/B64.jpg"
        alt="Pohled na interier"
      />
    </>
  ),
};

function DetailPage({ data }: PageProps<DetailPageQuery>): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { lastMapPath } = useContext(StoreContext);
  const currentCSVRow = data.lpsCsv;

  const LPSData = data.allLpsCsv.nodes;

  const content = (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        {imagesByCSVID[currentCSVRow.ID]}
      </Stack>
      <Detail currentId={data.lpsCsv.id} LPSData={LPSData} />
    </>
  );

  return (
    <>
      {isMobile && (
        <>
          <Toolbar />
          <Controls>
            <GoBackButton link={lastMapPath} />
          </Controls>
          {content}
        </>
      )}
      {isTabletOrDesktop && (
        <>
          <GoBackButton link={lastMapPath} />
          <Paper
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
          >
            {content}
          </Paper>
        </>
      )}
    </>
  );
}

export const query = graphql`
  query DetailPage($id: String!) {
    lpsCsv(id: { eq: $id }) {
      id
      ID
    }
    allLpsCsv {
      nodes {
        id
        provider
        address
        openDaily
        openHoliday
        openWeekend
        type
        latLng
        contact
        contactAlt
        www
        location
      }
    }
  }
`;

export default DetailPage;
