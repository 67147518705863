import { DirectionsCar, NearMe } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DirectionsIcon from "@mui/icons-material/Directions";
import DirectionsBusFilledOutlinedIcon from "@mui/icons-material/DirectionsBusFilledOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SubwayOutlinedIcon from "@mui/icons-material/SubwayOutlined";
import TramOutlinedIcon from "@mui/icons-material/TramOutlined";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Button, IconButton, Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { useEffect, useState } from "react";

import { useMapBoxSDK } from "../context/MapSDKContext";
import detailListQuery from "../staticQueries/detailListQuery";
import detailListTranslationQuery from "../staticQueries/detailListTranslationQuery";
import detailStaticMapQuery from "../staticQueries/detailStaticMapQuery";
import openHoursTranslationQuery from "../staticQueries/openHoursTranslationQuery";
import { LPSDataItem } from "../utils/common";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import getDistance from "../utils/getDistance";
import getLatLng from "../utils/getLatLng";
import { checkClosed, checkNonstop, getOpeningHours } from "../utils/timeUtils";
import DetailStaticMap from "./DetailStaticMap";
import withPublicTransportIcon from "./icons/withPublicTransportIcon";
import MapModal from "./MapModal";
import OpenInfo from "./OpenInfo";
import ParkingDetailListItem from "./ParkingDetailListItem";
import PublicTransportDetailListItem from "./PublicTransportDetailListItem";

interface Props extends LPSDataItem {
  contact?: string;
  contactAlt?: string;
  www?: string;
  location?: string;
  id: string;
}

const MetroIconLocal = withPublicTransportIcon(SubwayOutlinedIcon, false);
const BusIconLocal = withPublicTransportIcon(
  DirectionsBusFilledOutlinedIcon,
  false
);
const TramIconLocal = withPublicTransportIcon(TramOutlinedIcon, false);

function DetailList({
  provider,
  address,
  contact,
  contactAlt,
  www,
  openDaily,
  openWeekend,
  location,
  id,
}: Props): JSX.Element {
  const {
    bus,
    busLatLng,
    metro,
    metroLatLng,
    tram,
    tramLatLng,
    parkingName,
    parkingAltName,
    parkingLatLng,
    parkingAltLatLng,
  } = detailListQuery(id);
  const { mondayToFriday, weekend } = openHoursTranslationQuery();
  const {
    hospitalMap,
    publicTransportNearby,
    busStop,
    tramStop,
    metroStop,
    parkingNearby,
    enlarge,
  } = detailListTranslationQuery();
  const current = getLatLng(
    detailStaticMapQuery().find((item) => item.node.id === id).node.latLng
  );

  const [walkingDistance, setWalkingDistance] = useState();
  const [drivingDuration, setDrivingDuration] = useState();

  const [mapModal, setMapModal] = useState(false);
  const [openHoursList, setOpenHoursList] = useState(false);
  const toggleOpenHoursList = () => setOpenHoursList(!openHoursList);

  const addressText = address.replace(/["]/g, "");
  const { directionClient } = useMapBoxSDK();

  useEffect(() => {
    (async () => {
      const position = await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );

      directionClient
        .getDirections({
          profile: "walking",
          waypoints: [
            {
              coordinates: [
                position.coords.longitude,
                position.coords.latitude,
              ],
            },
            { coordinates: [current.lng, current.lat] },
          ],
        })
        .send()
        .then(({ body }) => {
          setWalkingDistance(body.routes[0].distance);
        });
      directionClient
        .getDirections({
          profile: "driving",
          waypoints: [
            {
              coordinates: [
                position.coords.longitude,
                position.coords.latitude,
              ],
            },
            { coordinates: [current.lng, current.lat] },
          ],
        })
        .send()
        .then(({ body }) => {
          setDrivingDuration(body.routes[0].duration / 60);
        });
    })();
  }, [directionClient]);

  return (
    <List>
      <List
        sx={{
          width: "95%",
          marginLeft: "2.5%",
        }}
      >
        <ListItem divider>
          <ListItemIcon>
            <LocationCityIcon
              fontSize="small"
              sx={{
                color: "primary.light",
              }}
            />
          </ListItemIcon>
          <ListItemText>{provider}</ListItemText>
        </ListItem>
        <ListItem
          divider
          secondaryAction={
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              to={`https://www.google.com/maps/dir/?api=1&destination=${addressText}`}
              edge="end"
              aria-label="navigate"
            >
              <DirectionsIcon color="primary" />
            </IconButton>
          }
        >
          <ListItemIcon>
            <MapOutlinedIcon
              fontSize="small"
              sx={{
                color: "primary.light",
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              location
                ? location[0].toUpperCase() +
                  location.substring(1).replace(/["]/g, "") +
                  " - " +
                  addressText
                : addressText
            }
            secondary={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                {walkingDistance && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0.5}
                  >
                    <NearMe
                      sx={{
                        fontSize: "12px",
                        color: "text.disabled",
                      }}
                    />{" "}
                    <Typography fontSize="12px" color="text.disabled">
                      {(walkingDistance / 1000).toFixed(1)} km
                    </Typography>
                  </Stack>
                )}{" "}
                {drivingDuration && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0.5}
                  >
                    <DirectionsCar
                      sx={{
                        fontSize: "12px",
                        color: "text.disabled",
                      }}
                    />{" "}
                    <Typography fontSize="12px" color="text.disabled">
                      {Math.round(drivingDuration)} min
                    </Typography>
                  </Stack>
                )}
              </Stack>
            }
          />
        </ListItem>
        <ListItem
          divider={!openHoursList}
          sx={{ paddingBottom: openHoursList ? 0 : "8px" }}
        >
          <ListItemIcon>
            <AccessTimeOutlinedIcon
              fontSize="small"
              sx={{
                height: "24px",
                color: "primary.light",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
              onClick={toggleOpenHoursList}
            >
              <OpenInfo
                nonstop={checkNonstop(openDaily, openWeekend)}
                closed={checkClosed(openDaily, openWeekend)}
                openingHours={getOpeningHours(openDaily, openWeekend)}
              />
              {openHoursList ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Stack>
          </ListItemText>
        </ListItem>
        {openHoursList && (
          <ListItem divider sx={{ paddingTop: 0 }}>
            <ListItemIcon />
            <ListItemText>
              <List sx={{ paddingTop: 0 }}>
                <ListItem disablePadding>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Typography fontSize="12px">
                      {mondayToFriday}: {openDaily.replace("-", " - ")}
                    </Typography>
                  </Stack>
                </ListItem>
                <ListItem disablePadding>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Typography fontSize="12px">
                      {weekend}: {openWeekend.replace("-", " - ")}
                    </Typography>
                  </Stack>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
        )}
        <ListItem divider>
          <ListItemIcon>
            <LocalPhoneOutlinedIcon
              fontSize="small"
              sx={{
                color: "primary.light",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0.5}
            >
              <Link to={`tel:${contact}`} underline="none">
                <Typography fontSize="12px">
                  {formatPhoneNumber(contact)}
                </Typography>
              </Link>
              {contactAlt && (
                <Link to={`tel:${contactAlt}`} underline="none">
                  <Typography fontSize="12px">
                    {formatPhoneNumber(contactAlt)}
                  </Typography>
                </Link>
              )}
            </Stack>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LanguageOutlinedIcon
              fontSize="small"
              sx={{
                color: "primary.light",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Link to={www} underline="none">
              <Typography fontSize="12px">{www}</Typography>
            </Link>
          </ListItemText>
        </ListItem>
      </List>

      <ListItem>
        <Typography marginTop={2}>{publicTransportNearby}</Typography>
      </ListItem>
      <List
        sx={{
          width: "95%",
          marginLeft: "2.5%",
        }}
      >
        <PublicTransportDetailListItem
          icon={<TramIconLocal />}
          label={tramStop}
          stops={tram}
          divider={!!bus || !!metro}
          distance={getDistance(current, getLatLng(tramLatLng))}
        />
        <PublicTransportDetailListItem
          icon={<BusIconLocal />}
          label={busStop}
          stops={bus}
          divider={!!metro}
          distance={getDistance(current, getLatLng(busLatLng))}
        />
        <PublicTransportDetailListItem
          icon={<MetroIconLocal />}
          label={metroStop}
          stops={metro}
          distance={getDistance(current, getLatLng(metroLatLng))}
        />
      </List>

      {!!parkingName && (
        <>
          <ListItem>
            <Typography marginTop={2}>{parkingNearby}</Typography>
          </ListItem>
          <List
            sx={{
              width: "95%",
              marginLeft: "2.5%",
            }}
          >
            <ParkingDetailListItem
              divider={!!parkingAltName}
              label={parkingName}
              latLng={getLatLng(parkingLatLng)}
              distance={getDistance(current, getLatLng(parkingLatLng))}
            />
            {!!parkingAltName && (
              <ParkingDetailListItem
                label={parkingAltName}
                latLng={getLatLng(parkingAltLatLng)}
                distance={getDistance(current, getLatLng(parkingAltLatLng))}
              />
            )}
          </List>
        </>
      )}
      <ListItem
        sx={{
          marginTop: 2,
        }}
      >
        <Stack direction="column" width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Typography paddingBottom={1}>{hospitalMap}</Typography>
            <Button onClick={() => setMapModal(true)} variant="text">
              <Typography color="primary">{enlarge}</Typography>
            </Button>
          </Stack>
          <DetailStaticMap width="100%" height="211px" zoom={14} id={id} />
        </Stack>
        <MapModal open={mapModal} id={id} onClose={() => setMapModal(false)} />
      </ListItem>
    </List>
  );
}

export default DetailList;
