import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog, Stack, Typography } from "@mui/material";
import { Button, IconButton } from "gatsby-theme-material-ui";
import * as React from "react";

import detailListTranslationQuery from "../staticQueries/detailListTranslationQuery";
import guideModalQuery from "../staticQueries/guideModalQuery";
import DetailStaticMap from "./DetailStaticMap";

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
}

function MapModal({ open, id, onClose }: Props): JSX.Element {
  const { hospitalMap } = detailListTranslationQuery();
  const {
    common: { close },
  } = guideModalQuery();

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          margin: 0,
          width: "100%",
        },
      }}
    >
      <Stack direction="column" width="100%" padding="10px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          padding="5px 0 10px 0"
        >
          <Typography variant="h5">{hospitalMap}</Typography>
          <IconButton onClick={onClose} aria-label="language switch">
            <CancelIcon sx={{ color: "grey.500", fontSize: "24px" }} />
          </IconButton>
        </Stack>
        <DetailStaticMap width="100%" height="500px" zoom={14} id={id} />
        <Button onClick={onClose} variant="text">
          <Typography color="text.primary" paddingTop="10px">
            {close}
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
}

export default MapModal;
