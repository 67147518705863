import { Tab, Tabs } from "@mui/material";
import { navigate } from "gatsby";
import * as React from "react";

import LPSIconLabelsQuery from "../staticQueries/LPSIconLabelsQuery";
import { LPS, LPSDataProps } from "../utils/common";
import { getLPSType } from "../utils/getLPSType";
import DetailList from "./DetailList";
import LPSIcon from "./LPSIcon";

interface Props extends LPSDataProps {
  currentId: string;
}

function Detail({ currentId, LPSData }: Props): JSX.Element {
  const current = LPSData.filter((item) => item.id === currentId)[0];
  const available = LPSData.filter(
    (item) => item.provider === current.provider
  );
  const availableBookmarks = [
    ...available.map((item) => getLPSType(item.type)),
  ];

  const { LPSIconLabels } = LPSIconLabelsQuery();

  return (
    <>
      <Tabs
        value={getLPSType(current.type)}
        onChange={(_, newValue) => {
          const newId = available.find(
            (item) => getLPSType(item.type) === newValue
          ).id;
          void navigate(`/detail/${newId}`);
        }}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        sx={{
          backgroundColor: "primary.main",
        }}
      >
        {Object.keys(LPSIconLabels).map((type: LPS) => {
          const disabled = !availableBookmarks.includes(type);
          const isCurrent = type === getLPSType(current.type);
          const currentStyles = isCurrent
            ? {
                borderRadius: "5px 5px 0 0",
                backgroundColor: "background.paper",
                color: "text.primary",
              }
            : {
                backgroundColor: "background.primary",
                color: "background.checkedTag",
              };

          return (
            <Tab
              key={type}
              value={type}
              icon={
                <LPSIcon
                  type={type}
                  width="30"
                  height="24"
                  fill={
                    disabled ? "#FFFFFF61" : isCurrent ? "#BA2525" : "#FFEEEE"
                  }
                />
              }
              label={LPSIconLabels[type]}
              disabled={disabled}
              sx={{
                ...currentStyles,
                marginTop: "5px",
                "&.Mui-disabled": {
                  color: "#FFFFFF61",
                },
                "&.MuiTab-root": {
                  fontSize: "13px",
                },
              }}
            />
          );
        })}
      </Tabs>
      <DetailList {...current} />
    </>
  );
}

export default Detail;
