import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";

import DistanceToTransport from "./DistanceToTransport";

interface Props {
  icon: ReactNode;
  label: string;
  stops: string;
  divider?: boolean;
  distance: string;
}

function PublicTransportDetailListItem({
  icon,
  label,
  stops,
  divider,
  distance,
}: Props): JSX.Element {
  return (
    stops && (
      <ListItem divider={divider}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0}
          >
            <Typography fontSize="12px">{label}</Typography>
            <Typography fontSize="12px" color="info.main">
              {stops.replace(/["/]/g, "")}
            </Typography>
            <DistanceToTransport distance={distance} />
          </Stack>
        </ListItemText>
      </ListItem>
    )
  );
}

export default PublicTransportDetailListItem;
