import { graphql, useStaticQuery } from "gatsby";

import { DetailListQuery } from "../graphqlTypes";

const detailListQuery = (
  id: string
): DetailListQuery["allLpsCsv"]["nodes"][number] => {
  const { allLpsCsv } = useStaticQuery<DetailListQuery>(graphql`
    query DetailList {
      allLpsCsv {
        nodes {
          bus
          metro
          tram
          id
          parkingAltName
          parkingName
          busLatLng
          parkingLatLng
          tramLatLng
          parkingAltLatLng
          metroLatLng
        }
      }
    }
  `);

  return allLpsCsv.nodes.find((item) => item.id === id);
};

export default detailListQuery;
