import { degr2rad } from "./getLatLngCenter";

type latLng = { lat: number; lng: number };

function getCrowDistance(latLng1: latLng, latLng2: latLng): number {
  const R = 6371; // km
  const dLat = degr2rad(latLng2.lat - latLng1.lat);
  const dLon = degr2rad(latLng2.lng - latLng1.lng);
  const lat1 = degr2rad(latLng1.lat);
  const lat2 = degr2rad(latLng2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(R * c * 1000); // m
}

export default function getDistance(current: latLng, other: latLng): string {
  return getCrowDistance(current, other).toString();
}
