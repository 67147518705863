import DirectionsWalkRoundedIcon from "@mui/icons-material/DirectionsWalkRounded";
import { Stack, Typography } from "@mui/material";
import * as React from "react";

interface Props {
  distance: string;
}

function DistanceToTransport({ distance }: Props): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      <DirectionsWalkRoundedIcon
        color="disabled"
        sx={{
          fontSize: "12px",
        }}
      />
      <Typography fontSize="12px" lineHeight="14px" color="text.disabled">
        {distance + " m"}
      </Typography>
    </Stack>
  );
}

export default DistanceToTransport;
