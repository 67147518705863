import { SvgIconComponent } from "@mui/icons-material";
import { Stack } from "@mui/material";
import * as React from "react";

function withPublicTransportIcon<T>(
  ComponentIcon: SvgIconComponent,
  isCircle = true
) {
  return function wrapperPublicTransportIcon(props: T) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 32,
          height: 36,
          bgcolor: isCircle
            ? "background.publicTransport"
            : "background.default",
          borderRadius: "50%",
          border: "1px solid white",
        }}
      >
        <ComponentIcon
          fontSize={isCircle ? "medium" : "large"}
          sx={{
            color: isCircle ? "common.white" : "info.main",
            ...props.sx,
          }}
          {...props}
        />
      </Stack>
    );
  };
}

export default withPublicTransportIcon;
