import LocalParkingIcon from "@mui/icons-material/LocalParking";
import { SvgIconProps } from "@mui/material";
import * as React from "react";

function ParkingIcon(props: SvgIconProps): JSX.Element {
  return (
    <LocalParkingIcon
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "26px",
        height: "26px",
        bgcolor: "background.publicTransport",
        borderRadius: "3px",
        border: "1px solid white",
        color: "common.white",
        ...props.sx,
      }}
      {...props}
    />
  );
}

export default ParkingIcon;
