import { graphql, useStaticQuery } from "gatsby";

import { DetailStaticMapQuery } from "../graphqlTypes";

const detailStaticMapQuery = (): DetailStaticMapQuery["allLpsCsv"]["edges"] => {
  const { allLpsCsv } = useStaticQuery<DetailStaticMapQuery>(graphql`
    query DetailStaticMap {
      allLpsCsv {
        edges {
          node {
            id
            latLng
            type
            tramLatLng
            parkingLatLng
            parkingAltLatLng
            openWeekend
            openDaily
            openHoliday
            metroLatLng
            provider
            busLatLng
          }
        }
      }
    }
  `);

  return allLpsCsv.edges;
};

export default detailStaticMapQuery;
