import DirectionsBusFilledOutlinedIcon from "@mui/icons-material/DirectionsBusFilledOutlined";
import SubwayOutlinedIcon from "@mui/icons-material/SubwayOutlined";
import TramOutlinedIcon from "@mui/icons-material/TramOutlined";
import * as React from "react";
import { ComponentType, useEffect, useState } from "react";
import StaticMap, { Marker } from "react-map-gl";

import detailStaticMapQuery from "../staticQueries/detailStaticMapQuery";
import LPSIconLabelsQuery from "../staticQueries/LPSIconLabelsQuery";
import { IMarker } from "../utils/common";
import getLatLng from "../utils/getLatLng";
import getLatLngCenter from "../utils/getLatLngCenter";
import { getLPSType } from "../utils/getLPSType";
import getMarker from "../utils/getMarker";
import { checkClosed, checkNonstop, getOpeningHours } from "../utils/timeUtils";
import ParkingIcon from "./icons/ParkingIcon";
import withPublicTransportIcon from "./icons/withPublicTransportIcon";
import MarkerWrapper from "./MarkerWrapper";

interface Props {
  width: string;
  height: string;
  zoom: number;
  id: string;
}

function withPublicTransportMarker<T>(ComponentIcon: ComponentType<T>) {
  return function wrapperPublicTransportMarker({
    lat,
    lng,
    ...props
  }: T & {
    lat: number;
    lng: number;
  }) {
    return (
      <Marker latitude={lat} longitude={lng}>
        <ComponentIcon {...props} />
      </Marker>
    );
  };
}

const ParkIconLocal = withPublicTransportMarker(
  withPublicTransportIcon(ParkingIcon),
  false
);

const MetroIconLocal = withPublicTransportMarker(
  withPublicTransportIcon(SubwayOutlinedIcon)
);

const TramIconLocal = withPublicTransportMarker(
  withPublicTransportIcon(TramOutlinedIcon)
);

const BusIconLocal = withPublicTransportMarker(
  withPublicTransportIcon(DirectionsBusFilledOutlinedIcon)
);

function DetailStaticMap({ width, height, zoom, id }: Props): JSX.Element {
  //force the map to rerender markers workaround
  // beginning
  const [delayedZoom, setDelayedZoom] = useState(12);
  const delay = (zoom: number) => {
    setTimeout(() => setDelayedZoom(zoom), 10);
  };
  useEffect(() => {
    delay(zoom);
  }, []);
  // end

  const { LPSIconLabels } = LPSIconLabelsQuery();

  const current = detailStaticMapQuery().find(
    (item) => item.node.id === id
  ).node;
  const affiliated = detailStaticMapQuery().filter(
    (item) => item.node.provider === current.provider
  );

  const parkingMarkers = [
    getLatLng(current.parkingLatLng),
    getLatLng(current.parkingAltLatLng),
  ];
  const metroMarker = getLatLng(current.metroLatLng);
  const tramMarker = getLatLng(current.tramLatLng);
  const busMarker = getLatLng(current.busLatLng);
  const currentMarker = getMarker(current);
  const affiliatedMarkers = affiliated
    .filter((item) => item.node.id !== id)
    .map((item) => getMarker(item.node));

  const listMarkers = (markers: IMarker[]) =>
    markers.map((item, key) => {
      return (
        <MarkerWrapper
          key={key}
          id={item.id}
          lat={item.lat}
          lng={item.lng}
          type={item.type}
          state={item.state}
        />
      );
    });

  const center = getLatLngCenter([
    { lat: currentMarker.lat, lng: currentMarker.lng },
    ...affiliatedMarkers.map((item) => {
      return { lat: item.lat, lng: item.lng };
    }),
  ]);

  return (
    <StaticMap
      style={{ width, height }}
      zoom={delayedZoom}
      latitude={center[0]}
      longitude={center[1]}
      touchZoomRotate={false}
      touchPitch={false}
      scrollZoom={false}
      keyboard={false}
      dragPan={false}
      dragRotate={false}
      doubleClickZoom={false}
      boxZoom={false}
      mapboxAccessToken={process.env.MAPBOX_API_ACCESS_TOKEN}
      mapStyle={process.env.MAPBOX_MAP_STYLE}
    >
      {parkingMarkers.map(
        (item, key) =>
          item.lat && <ParkIconLocal key={key} lat={item.lat} lng={item.lng} />
      )}
      {metroMarker.lat && (
        <MetroIconLocal lat={metroMarker.lat} lng={metroMarker.lng} />
      )}
      {busMarker.lat && (
        <BusIconLocal lat={busMarker.lat} lng={busMarker.lng} />
      )}
      {tramMarker.lat && (
        <TramIconLocal lat={tramMarker.lat} lng={tramMarker.lng} />
      )}
      {listMarkers(affiliatedMarkers)}
      <MarkerWrapper
        id={currentMarker.id}
        lat={currentMarker.lat}
        lng={currentMarker.lng}
        type={currentMarker.type}
        state={currentMarker.state}
        markerFlag={{
          typeLabel: LPSIconLabels[getLPSType(current.type)],
          closed: checkClosed(current.openDaily, current.openWeekend),
          nonstop: checkNonstop(current.openDaily, current.openWeekend),
          openingHours: getOpeningHours(current.openDaily, current.openWeekend),
        }}
      />
    </StaticMap>
  );
}

export default DetailStaticMap;
